<template>
<div>
    <div class="form-control">
        <div class="relative">
            <input type="text" placeholder="Search" class="w-full pr-16 input input-primary input-bordered text-black bg-gray-100"
                 v-model="mbi" :class="[{'input-success': valid}, {'input-error': (has_checked && !valid)}]" > 
            <button class="absolute top-0 right-0 rounded-l-none btn btn-primary" @click="validateMBi" >Validate MBI</button>
        </div> 
    </div> 
    <div v-if="has_checked == true && valid == true" class="alert alert-success">
        <div class="flex-1">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 mx-2 stroke-current">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>                          
            </svg> 
            <label class="text-black" >MBI is valid!</label>
        </div>
    </div>
    <div v-if="has_checked == true && valid == false" class="alert alert-error">
        <div class="flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 mx-2 stroke-current">    
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>                      
            </svg> 
            <label>MBI is NOT valid!</label>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'generator',
    data() {
        return {
            mbi: '',
            valid: false,
            has_checked: false,
        }
    },
    methods: {
        validateMBi() {

            if(this.mbi.length == 0) {
                alert('Please enter an MBI value');
                return;
            }

            this.$http.post(`${this.api_url}/verify?mbi=${this.mbi}`)
            .then(res => {
                this.valid = res.data.valid;
                this.has_checked = true;
            });
        },
    },
}
</script>

<style>
</style>
