/**
 * @see https://cli.vuejs.org/guide/installation.html
 */
import Vue from 'vue';
import App from './App.vue';
import Axios from 'axios';

Vue.config.productionTip = true;

Vue.prototype.$http = Axios;

console.log(process, process.env, process.env.VUE_APP_API_URL, process.env.VUE_APP_API_URL);

Vue.mixin({
	data: function(){
        // eslint-disable-next-line
	    return {
            // eslint-disable-next-line
            api_url: process.env.VUE_APP_API_URL,
        };
    },
});

new Vue({
  render: h => h(App),
}).$mount('#app');