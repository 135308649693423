<template>
<div>
    <div class="form-control">
        <div class="relative">
            <input type="text" placeholder="Search" class="w-full pr-16 input input-primary input-bordered text-black bg-gray-100" v-model="mbi"> 
            <button class="absolute top-0 right-0 rounded-l-none btn btn-primary" @click="generateMBI" >Generate MBI</button>
        </div> 
    </div> 

</div>
</template>

<script>
export default {
    name: 'validator',
    data() {
        return {
            mbi: '',
        }
    },
    methods: {
        generateMBI() {
            this.$http.get(`${this.api_url}/generate`)
            .then(res => {
                this.mbi = res.data.mbi;
            });
        },
    },
}
</script>

<style>
</style>