<template>
    <div id="app">
        <div class="container mx-auto w-full sm:w-3/4 md:w-5/6 lg:w-1/2">

            <h1 class="text-4xl " >{{title}}</h1>

            <div class="m-6">
                <Generator/>
            </div>

            <div class="m-6">
                <Validator/>
            </div>
        </div>
    </div>
</template>

<script>
import Generator from './components/Generator.vue';
import Validator from './components/Validator.vue';

export default {
  name: 'App',
  components: {
    Generator,
    Validator,
  },
  data(){
      return {
          title: process.env.VUE_APP_TITLE,
      };
  },
}
</script>

<style>
:root{
    background-color: #fff;
    color: #080808;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
